.public-wall-grid {
  display: grid;
  grid-template-columns: repeat(var(--grid-cols, 1), minmax(0, 1fr));
}

/* Default: 1 column */
.public-wall-grid {
  --grid-cols: 1;
}

/* sm: custom columns */
@media (min-width: 640px) {
  .public-wall-grid {
    --grid-cols: var(--grid-cols-sm, 2);
  }
}

/* md: custom columns */
@media (min-width: 768px) {
  .public-wall-grid {
    --grid-cols: var(--grid-cols-md, 3);
  }
}

/* lg: custom columns */
@media (min-width: 1024px) {
  .public-wall-grid {
    --grid-cols: var(--grid-cols-lg, 4);
  }
}

/* xl: custom columns */
@media (min-width: 1280px) {
  .public-wall-grid {
    --grid-cols: var(--grid-cols-xl, 5);
  }
}

/* 2xl: custom columns */
@media (min-width: 1536px) {
  .public-wall-grid {
    --grid-cols: var(--grid-cols-2xl, 6);
  }
}

/* 3xl: custom columns */
@media (min-width: 1920px) {
  .public-wall-grid {
    --grid-cols: var(--grid-cols-3xl, 7);
  }
}

/* 4xl: custom columns */
@media (min-width: 2560px) {
  .public-wall-grid {
    --grid-cols: var(--grid-cols-4xl, 8);
  }
}

/* 5xl: custom columns */
@media (min-width: 3440px) {
  .public-wall-grid {
    --grid-cols: var(--grid-cols-5xl, 9);
  }
}

/* Responsive popup modal for mobile devices */
@media (max-width: 768px) {
  .popup-modal {
    flex-direction: column;
  }

  .popup-modal .image-container {
    width: 100%;
    max-height: 60vh;
  }

  .popup-modal .content-container {
    width: 100%;
    max-height: 35vh;
    overflow-y: auto;
  }
}